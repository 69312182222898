import React, { ReactElement } from 'react';

import { useDataSource } from 'leeloo-react/component/hook/useDataSource';
import { RouterLink } from 'leeloo-react/component/router/RouterLink';
import { Breadcrumb } from 'react-bootstrap';

import { ELearningDto } from '../../../model/dto/ELearningDto';
import { FormationCard } from '../../formation/FormationCard';
import { BaseContainer } from '../../layout/BaseContainer';

type ParentPage =  {
  title:string,
  route:string
}
export function ThematicAbstractPage(props : {resource: string, parent:ParentPage, title:string, flip : boolean}): ReactElement {
  // On va récupérer la thématique en cours
  const dsFormations = useDataSource<ELearningDto>({
    resource: props.resource,
  });

  return (
    <BaseContainer>
      <Breadcrumb>
        <Breadcrumb.Item>
          <RouterLink routeName={props.parent.route}>
            {props.parent.title}
          </RouterLink>
        </Breadcrumb.Item>
        <Breadcrumb.Item active>
          {props.title}
        </Breadcrumb.Item>
      </Breadcrumb>
      <h2 className="plus mb-4">
        {props.title}
      </h2>
      {/* eslint-disable-next-line react/jsx-no-useless-fragment */}
      <>
        {dsFormations.total > 0 && (
          <div className="row">
            {dsFormations.dataList.map((formation) => (
              <div key={formation.id} className="col-6 col-md-4 col-lg-3 col-xl-3 col-xxl-2 mb-4">
                <FormationCard
                  formation={formation}
                  flip={props.flip}
                />
              </div>
            ))}
          </div>
        )}
      </>
    </BaseContainer>
  );
}
