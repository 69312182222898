import './jumbotron.css';
import dateFormat from 'dateformat';

function Footer() {
  return (
    <div className="footerDoc">
      <p>Le service formation</p>
      <p>formation@guy-hoquet.com</p>
      <p>01 58 46 44 44</p>
    </div>
  );
}
function Convocation({ item }) {
  return (
    <>
      <h4 className="boxTitle">Votre Convocation à une formation est disponible</h4>
      <p className="mt-3">
        Bonjour
        {' '}
        {item?.name_contact}
        ,
      </p>
      <p>
        Nous avons le plaisir de vous convier à la formation
        {' '}
        {item?.name_training}
        {' '}
        qui se déroulera
        du
        <b>{' '}{dateFormat(item?.date_start, 'dd/mm/yyyy')}</b>
        {' '}
        au
        <b>{' '}{dateFormat(item?.date_end, 'dd/mm/yyyy')}</b>
        .
        <br/>
        Nous vous donnons rendez-vous à :
        {' '}
        {item?.location}
        {' '}
        {item.town && `, ${item.town}`}
        {' '}
        à partir de
        {' '}
        {item.start_hour}
        .
        <br/>
        En cas d’empêchement, merci de nous en informer au plus vite.
        <br/>
        La convention de formation
        est disponible
        sur votre plateforme de formation dans votre espace profil.
        Le pôle formation se tient à votre disposition pour répondre à toutes
        vos questions.
      </p>
      <Footer />
    </>
  );
}
function Confirmation({ item }) {
  return (
    <>
      <h4 className="boxTitle">Votre Confirmation d’inscription est disponible</h4>
      <p className="mt-3">
        Bonjour
        <b>{item?.name_contact}</b>
        {' '}
        ,
      </p>
      <p>
        Nous avons le plaisir de vous confirmer votre inscription à la formation
        <b>{item?.name_training}</b>
        {' '}
        qui se déroulera
        du
        <b>{dateFormat(item?.date_start, 'dd/mm/yyyy')}</b>
        {' '}
        au
        <b>{dateFormat(item?.date_end, 'dd/mm/yyyy')}</b>
        {' '}
        .
        <br />
        Vous recevrez 48h avant le début de la formation la convocation finale par mail.

        Dans l’attente de vous retrouver, merci de répondre au questionnaire de positionnement de votre formation en
        {' '}
        <a href="https://forms.office.com/r/D0iNJYZNdC">cliquant ici</a>

        En cas d’empêchement, merci de nous en informer au plus vite.

        Le pôle formation se tient à votre disposition pour répondre à toutes vos questions.

      </p>
      <Footer />
    </>
  );
}
function Annulation({ item }) {
  return (
    <>
      <h4 className="boxTitle">Annulation de votre formation</h4>
      <p className="mt-3">
        Bonjour
        <b>{item?.name_contact}</b>
        ,
      </p>
      <p>
        Nous sommes au regret de vous informer que la formation
        <b>
          {item?.name_training}
          {' '}
        </b>
        pour laquelle vous étiez inscrit aux dates suivantes :
        {' '}
        <b>{dateFormat(item?.date_start, 'dd/mm/yyyy')}</b>
        {' '}
        au
        <b>{dateFormat(item?.date_end, 'dd/mm/yyyy')}</b>
        ne remplit pas les conditions nécessaires à son maintien.
        {' '}
        <br />

        Le pôle formation se tient à votre disposition pour répondre à toutes vos questions.

      </p>
      <Footer />
    </>
  );
}

function Inscription({ item }) {
  return (
    <>
      <h4 className="boxTitle">Inscription à une session dans le catalogue</h4>
      <p className="mt-3">
        Bonjour
        <b>{' '}{item?.name_contact}</b>
        ,
      </p>
      <p>
        Nous avons le plaisir de vous informer de votre inscription à la formation
        <b>{' '}{item?.name_training}</b>
        .
        <br/>
        Votre formation se déroulera
        du
        {' '}
        <b>{dateFormat(item?.date_start, 'dd/mm/yyyy')}</b>
        {' '}
        au
        {' '}
        <b>{dateFormat(item?.date_end, 'dd/mm/yyyy')}</b>
        .
        <br/>
        Une convocation avec les horaires et le lieu vous sera envoyée
        prochainement.
        <br/>
        Le pôle formation se tient à votre disposition pour répondre à toutes
        vos questions.

      </p>
      <Footer />
    </>
  );
}

function Convention({ item }) {
  return (
    <>
      <h4 className="boxTitle">Convention de formation</h4>
      <p className="mt-3">
        Bonjour
        <b>{item?.name_contact}</b>
        ,
      </p>
      <p>
        Suite à l’inscription à la formation
        <b>{item?.name_training}</b>
        ,
        nous avons le plaisir de vous informer que la convention de formation est disponible
        sur votre plateforme de formation dans votre espace profil.
        <br />
        Votre formation se déroulera le :
        {' '}
        {dateFormat(item?.date_start, 'dd/mm/yyyy')}
        {' '}
        à partir de
        {' '}
        {item.start_hour}
        {' '}
        à l'adresse suivante :
        {' '}
        {item.location}
        {item.town && `, ${item.town}`}
        <br />
        Le pôle formation se tient à votre disposition pour répondre à toutes vos questions.

      </p>
      <Footer />
    </>
  );
}
function Suiteformation({ item }) {
  return (
    <>
      <h4 className="boxTitle">Convention de formation</h4>
      <p className="mt-3">
        Bonjour
        <b>{item?.name_contact}</b>
        ,
      </p>
      <p>
        Merci d’avoir participé à la formation
        <b>{item?.name_training}</b>
        {' '}
        ,
        Dans une volonté constante de vous proposer la meilleure expérience de formation possible,
        merci de bien vouloir prendre quelques minutes pour nous faire part de votre avis en
        <a href="https://forms.office.com/r/98kxprr3A6">cliquant ici </a>

      </p>
      <p>Votre retour nous est précieux afin de répondre au mieux à vos attentes.</p>

      <p>
        Le pôle formation se tient à votre disposition pour répondre à toutes vos questions.

      </p>
      <Footer />
    </>
  );
}
function Emargement({ item }) {
  return (
    <>
      <h4 className="boxTitle">Convention de formation</h4>
      <p className="mt-3">
        Bonjour
        <b>{item?.name_contact}</b>
        ,
      </p>
      <p>
        Nous avons le plaisir de vous faire parvenir les feuilles d’émargement de la formation
        <b>{item?.name_training}</b>
        {' '}
        ,
        qui se déroulera du
        <b>{dateFormat(item?.date_start, 'dd/mm/yyyy')}</b>
        {' '}
        au
        <b>{dateFormat(item?.date_end, 'dd/mm/yyyy')}</b>
        Merci de les faire signer à tous les participants et de nous les retourner au plus vite.
        <br />
        Le pôle formation se tient à votre disposition pour répondre à toutes vos questions.

      </p>
      <Footer />
    </>
  );
}

export default function Jumbotron({ data, type }) {
  // const [type, setType] = useState(data?.data?.type)

  function RenderSwitch({ param }) {
    switch (param) {
    case 'convocation':
      return <Convocation item={data} />;
    case 'confirmation':
      return <Confirmation item={data} />;
    case 'annulation':
      return <Annulation item={data} />;
    case 'suiteformation':
      return <Suiteformation item={data} />;
    case 'convention':
      // Suite aux demandes client, on branche l'état "convention" sur le
      //   composant "Inscription" plutôt que "Convention" pour
      //   signifier au client que l'inscription à une session de formation
      //   a été faite, mais que ce n'est pour l'instant qu'un prévision.
      // La notion de convention sera évoquée à l'étape convocation.
      // Pour rendre les choses plus propres, il faudrait reprendre les données
      //   backoffice et mettre un flag "inscription" à la place de "convention"
      return <Inscription item={data} />;
    case 'emargement':
      return <Emargement />;
    default:
      return 'Aucun message disponible';
    }
  }
  return (
    <div>
      <div className="text-white rounded-3 jumbotron">
        <div className="box h-100 p-4 p-xl-5 ">
          <RenderSwitch param={type?.trim()} />

        </div>
      </div>
    </div>

  );
}
